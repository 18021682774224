import CustomImageComponent from "../CustomImage/CustomImageComponent";

export const PrevArrowV2 = ({onClick, showInMobile=false,left="-40px",top="50%"}) => {
    return (
        <div className="arrow prev" onClick={onClick}>
            <style jsx>{`
              .arrow {
                position: absolute;
                cursor: pointer;
                z-index: 100;
              }

              .prev {
                left: ${left || "-40px"};
                top: ${top || "50%"};
              }

              @media screen and (max-width: 576px) {
                .arrow {
                  display: ${showInMobile?"flex":'none'};
                }
              }
            `}
            </style>
            <CustomImageComponent
                width={17}
                height={37}
                src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/prevArrowV2.svg"
                alt={"winner-quotes-image"}
            />
        </div>
    )
}

export const NextArrowV2 = ({onClick,showInMobile=false,right="-40px",top="50%"}) => {
    return (
        <div className="arrow next" onClick={onClick}>
            <style jsx>{`
              .arrow {
                position: absolute;
                cursor: pointer;
                z-index: 100;
              }

              .next {
                right: ${right || "-40px"};
                top: ${top || "50%"};
              }

              @media screen and (max-width: 576px) {
                .arrow {
                  display: ${showInMobile?"flex":'none'};
                }
              }
            `}
            </style>
            <CustomImageComponent
                width={17}
                height={37}
                src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/nextArrowV2.svg"
                alt={"image"}
            />
        </div>
    )
}
