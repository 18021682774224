import Slider from "react-slick";
import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import { IHowToInstallAppComponentProps } from "../../../lib/types/components";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import { NextArrowV2, PrevArrowV2 } from "../../common/ArrowComponent/ArrowComponentV2";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import DottedGrid from "../../common/Utils/DottedGrid";

const HowToInstallAppComponentV2 = (props: IHowToInstallAppComponentProps) => {

    const {howToInstallData} = props;
    if(!howToInstallData){
      return <></>
    }
    const stepsData = howToInstallData.stepsDataV2
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0px",
        nextArrow: <NextArrowV2 onClick/>,
        prevArrow: <PrevArrowV2 onClick/>,
        infinite: false,
        responsive: [
            {
                breakpoint: 757,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    dotsClass: "rating-slick-dots slick-dots slick-thumb",
                    appendDots: dots => (
                        <div
                            style={{
                                position: "static",
                            }}
                        >
                            <ul style={{margin: "0px"}}> {dots} </ul>
                        </div>
                    ),
                    customPaging: i => (
                        <div
                            style={{
                                width: dynamicCssSize(45, 13),
                                height: dynamicCssSize(7, 2),
                                backgroundColor: "rgba(255,255,255,0.3)",
                                borderRadius: "4px"
                            }}
                        ></div>
                    )
                }
            }
        ]
    };
    return (
        howToInstallData && Object.keys(howToInstallData).length?
            <H2TrapeziumBG uniqueContainerId={"howToInstall_container"}>
                <style jsx>
                    {`
                      .mainContainerHowToInstall {
                        background: linear-gradient(140deg, rgba(51, 0, 102, 1) 0%, rgba(221, 61, 77, 1) 50%, rgba(237, 112, 46, 1) 100%), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/pattern_how_to_install.webp"),
                        url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/soldier_elements1_HowToInstall.webp"), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/soldier_elements2_HowToInstall.webp");
                        background-repeat: no-repeat;
                        background-blend-mode: color;
                        background-size: cover, cover, 35%, 20%;
                        background-position: top, top, left bottom, right top;
                        color: white;
                        padding: 18px 0px 4.6vw;
                        font-family: var(--font-primary);
                      }

                      .headingParent {
                        display: flex;
                        justify-content: center;
                      }

                      .heading {
                        font-size: ${dynamicCssSize(60, 16)};
                        font-weight: 900;
                        font-style: italic;
                        letter-spacing: 2.25px;
                        max-width: 65vw;
                        text-align: center;
                        margin-top:10px;
                      }


                      .sliderContainer {
                        padding: ${dynamicCssSize(100, 20)} 10px;
                      }


                      .imageParent {
                        width: auto;
                        height: ${dynamicCssSize(500, 280)};
                        display: flex;
                        justify-content: center;
                      }

                      .imageContainer {
                        position: relative;
                        height: 100%;
                        width: ${dynamicCssSize(280, 150)};
                        border-radius: 10px;
                        box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.26);
                        border: 2px solid #fff;
                      }


                      .howToInstallContent {
                        text-align: center;
                        padding: 20px ${dynamicCssSize(50, 12)};
                        font-size: ${dynamicCssSize(18, 12)};
                        font-weight: 500;
                        font-style: italic;
                        color: #fff;
                      }

                      .howToInstallContent .step {
                        display: none;
                      }

                      @media screen and (max-width: 767px) {

                        .mainContainerHowToInstall {
                          background: linear-gradient(141deg, rgba(80, 16, 93, 1) 0%, rgba(240, 66, 44, 1) 100%), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/soldier_elements1_HowToInstall.webp");
                          background-repeat: no-repeat;
                          background-size: cover, 50%;
                          background-position: left bottom;
                          padding: 7px 0px 4.6vw;
                        }

                        .heading {
                          letter-spacing: 1px;
                          margin-bottom: 10px;
                        }

                        .howToInstallItem {
                          display: flex;
                          align-items: center;
                          flex-direction: row-reverse;
                        }

                        .howToInstallContent {
                          line-height: 1.25;
                        }

                        .howToInstallContent .step {
                          display: block;
                          font-weight: 600;
                          letter-spacing: 0.43px;
                        }

                        .howToInstallContent .desc {
                          padding: 10px 0px;
                          border-top: 2px solid white;
                          border-bottom: 2px solid white;
                        }

                        .imageParent {
                          width: 100%;
                        }

                        .imageContainer {
                          height: 95%;
                          box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.15);
                          border: 1px solid #ffffff;
                        }
                      }

                    `}
                </style>
                <div className={"container-fluid mainContainerHowToInstall"}>
                    <DottedGrid color={"#d8d8d8"} lines={3} cssMobileStyle={{
                        display: "none"
                    }} cssDesktopStyle={{
                        display: "block",
                        position: "absolute",
                        bottom: "0",
                        left: "3vw",
                        zIndex: 1000,
                    }}/>
                    <div className={`container `} id="id_howToInstall">
                        <div className={"headingParent"}>
                            <h2 className={"heading"}>
                                {howToInstallData.heading}
                            </h2>
                        </div>
                        <div className={"sliderContainer"}>
                            <Slider {...settings}>
                                {
                                    stepsData.map((step, index) => {
                                        return (
                                            <div key={"step_" + index}>
                                                <div className={"howToInstallItem"}>
                                                    <div className={"imageParent"}>
                                                        <div className={"imageContainer"}>
                                                            <CustomImageComponent
                                                                src={step.url}
                                                                alt="how to install steps"
                                                                objectFit={'cover'}
                                                                objectPosition={'50% 50%'}
                                                                layout={"fill"}
                                                                className={"howToInstallImageV2"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={"howToInstallContent"}>
                                                        <p className={"step"}>{step.step}</p>
                                                        <p className={"desc"}>{step.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>

                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default HowToInstallAppComponentV2;
