import { CSSProperties, useEffect, useState } from "react";
import { dynamicCssSize, isMobile } from "../../../lib/utils/constants/PagesConstants";

type Props = {
    lines: number,
    color: string,
    cssMobileStyle: CSSProperties,
    cssDesktopStyle: CSSProperties
}

type DottedLineProps = {
  index: number,
  color: string,
}

type AninationDelay = {
  animationOne: string, 
  animationTwo: string,
  animationThree: string
}

const DottedLine = (props: DottedLineProps) => {

  const [delayAnimation, setDelayAnimation] = useState<AninationDelay | undefined>(undefined)

  useEffect(() => {
    setDelayAnimation({
      animationOne: Math.floor(Math.random() * 1000) * props.index + 1 + "ms", 
      animationTwo: Math.floor(Math.random() * 1000) * props.index + 1 + "ms",
      animationThree: Math.floor(Math.random() * 1000) * props.index + 1 + "ms"
    })
  }, [])

  return (
    <>
      <style jsx>
        {`
          .item-container {
            display: flex;
          }

          @keyframes scaleAnimation {
            0% {
              transform: scale(1);
            }

            50% {
              transform: scale(0.4);
            }

            100% {
              transform: scale(1);
            }
          }
          .item-container > div {
            background-color: ${props.color};
            height: ${dynamicCssSize(15, 3)};
            width: ${dynamicCssSize(15, 3)};
            margin: ${dynamicCssSize(10, 2)};
            border-radius: 50%;
            animation-name: scaleAnimation;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
        `}
      </style>
      {
        delayAnimation ? <div className={"item-container"}>
          <div style={{animationDelay: delayAnimation!.animationOne}}></div>
          <div style={{animationDelay: delayAnimation!.animationTwo}}></div>
          <div style={{animationDelay: delayAnimation!.animationThree}}></div>
        </div> : <></>
      }
    </>
  )
}

const DottedGrid = (props: Props) => {
    const {lines, color, cssMobileStyle, cssDesktopStyle} = props
    return (
      <div className={"main-container"} style={isMobile ? cssMobileStyle : cssDesktopStyle}>
          {
              [...Array(lines)].map((item, index) => {
                  return (
                    <DottedLine index={index} color={color} key={"dotted_grid_" + index}/>
                  )
              })
          }
      </div>
    )
}

export default DottedGrid